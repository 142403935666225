const rules = `
## Summary

So what is *Study and Play*? Have you ever played Catchphrase? Time's Up? Taboo? Head's Up? Charades? 
If so, you already understand the basics of the game: *have your teammates guess words/phrases on a card
without being able to say the exact words shown". If that seems confusing, don't worry! The game is very simple.

Study and Play is a fun, engaging game seeking to increase the interactivity between teachers/students. It 
is also a useful tool studying. We learn best when explaining to others, but we learn even better 
when we're having fun! 

## Basics of the game

2 teams (RED, BLUE). During a team's turn, a "Clue Giver" is chosen that then receives a "card" containing a word
or a phrsae. The Clue Giver's goal is to get this card guessed by his/her teammates, word for word, WITHOUT
being able to say any of the words on the card. Once the entire phrase is guessed, the Clue Giver clicks on
"Correct" and then the game passes to the next "Clue Giver". It's necessary to guess quickly and not forget 
about the round timer!

### TNT mode

Each team starts with a number of life points pre-chosen by the game host. During a round, once a card is
correctly guessed, the "dynamite" passes to the other team. This continues back and forth between each team until
the timer runs out. The team holding the dynamite at this moment loses a life point. Last team standing wins.

## Carnival mode (coming soon...)

Each team starts with 0 points. During a single round, only one of the team's tries to get their cards correctly
guessed. A point is obtained for each successful card guess. After the timer runs out, the game passes to the other
team. The first team to score a certain number of points pre-chosen by the host wins the game!

## Example round (TNT mode)

Imagine 2 teams, with 2 players on the RED team and 2 players on the BLUE team:

- Player 1 (RED): Charlotte
- Player 2 (RED): Bobby
- Player 3 (BLUE): Danielle
- Player 4 (BLUE): David

 To start, a "Clue Giver" is selected from RED team, let's say that's Charlotte. When the round starts,
 Charlotte will see on her screen the phrase:

 **The French Revolution**

 Now Charlotte tries to get Bobby to guess the entire phrase "The French Revolution" by giving hints (she is
not allowed to say "The", "French", or "Revolution". She might say:

> Charlotte: "Okay this is a historic event, that took place in a European country."
> Bobby: "Uhhh what was the historic period? What happened during the event?"
> Charlotte: "It happened a while ago, during the 18th century. The people concerned were very angry against
> the rich aristocracy. Oh! These people also really liked cheese!"
> Bobby: "Ah! I think it's The French Revolution!"

Bobby correctly guessed the phrase and so the game passes to the BLUE team. This continues until the end of the round.
`
export default rules;