const rules = `
## Resumé

Mais qu'est-ce que c'est *Study and Play*? Est-ce que vous avez déjà joué à Catchphrase? Time's Up? Head's Up? 
Taboo? Charades? Si oui, vous comprendriez déjà le principe du jeu: *faire deviner des mots et des phrases
présents sur une carte sans pouvoir utiliser les mots exacts qui s'y trouvent*. Si tout cela vous apparaît comme de la charabia,
ne vous inquiètez pas! Le jeu est très simple.

Fondamentalement, Study and Play est un jeu ludique qui cherche à améliorer l'interactivité entre professeurs et étudiants.
Il sert également comme outil pour réviser des notions de cours et de concretiser des concepts déjà vus. Nous apprenons le mieux en
expliquant aux autres, mais *Study and Play* va un pas plus loin car il permet à l'utilisateur de s'amuser en même temps!

## Principe de jeu

2 équipes (ROUGE, BLEUE). Pendant le tour d'une équipe, un "Clue Giver" est selectionné qui reçoit sur son
écran une "carte" qui s'affiche un mot ou une phrase. Son but est de faire deviner (à ses coéquipiers) 
ce qui est affiché sur la carte (mot pour mot) SANS pouvoir lui-même dire ces mots-là. Une fois la phrase entière est trouvée, le "Clue Giver"
appuie sur "Correct" et cela passe au prochain "Clue Giver". Il faut deviner vite et faire attention au temps qui reste au Timer! 

## Mode de jeu - TNT

Chaque équipe commence avec un nombre de points de vie préalablement réglé par le hôte du jeu. Pendant un tour, chaque fois 
une carte est devinée correctement, le "dynamite" passe à l'autre équipe. Cela continue à faire va et vient entre les équipes 
jusqu'à ce que le chronomètre sonne. L'équipe qui tenant le dynamite à ce moment perd un de leurs points de vie. 
La dernière équipe debout gagne la partie!

## Mode de jeu - Carnival (à venir bientôt...)

Chaque équipe commence avec 0 points. Pendant un tour entier, une de ses équipes fait deviner autant de mots possible, et 
chaque fois une carte est devinée correctement, l'équipe qui joue marque un point. Après que le chronomètre sonne, le tour passe
à l'autre équipe avec le même fonctionnement. La première équipe qui marque un certain nombre de points préalablement réglé par le hôte 
du jeu gagne la partie!

## Exemple de tour (mode TNT)

Imaginez 2 équipes, avec 2 joueurs sur l'équipe ROUGE et 2 joueurs sur l'équipe BLEUE.

- Joueur 1 (ROUGE): Charlotte
- Joueur 2 (ROUGE): Bobby
- Joueur 3 (BLEUE): Danielle
- Joueur 4 (BLEUE): David

Pour commencer, un "Clue Giver" est selectionné de l'équipe ROUGE, disons Charlotte. Quand le tour démarre, 
Charlotte voit sur son écran la phrase:

**La revolution américaine**

Maintenant Charlotte essaie de faire deviner cette phrase par Bobby, en donnant des indices mais sans pouvoir utiliser
les mots "Les", "révolution", et "américaine". Voici un exemple de comment elle s'en sort:

> Charlotte: "Ok hmmm... elle a été une période d'histoire qui a eu lieu dans un pays non-européen."
> Bobby: "Bah, quelle période à peu près? Qu'est-ce qui s'est passé pendant ce temps?"
> Charlotte: "Ça s'est passé il y a longtemps, au 18ième siècle. Les gens ont été très fâchés par les britanniques! Ooo! Aussi ces gens-là
> aiment les hot dogs!
> Bobby: "Ahh! Je crois que c'est **la révolution américaine**!"

Bobby a deviné la phrase correctement donc le jeu passe à l'équipe BLEUE, et ainsi de suite jusqu'à la fin du tour.
`

export default rules;