<template>
  <div>

    <Navbar></Navbar> 
    <GenericHeader :title="$t('view.howto.header')"></GenericHeader>

    <!-- MARKDOWN CONTENT IS PASSED INTO THIS DIV -->
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="9" lg="7" xl="5">
          <div
          class="box-default p-4"
          style="font-size: 1.2rem;"
          v-html="compiledMarkdown"></div>
        </b-col>
      </b-row>
    </b-container>


  </div>
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import GenericHeader from '@/components/GenericHeader.vue'
import { mapState } from 'vuex'
import marked from 'marked'

import rules_en_md from '@/data/rules_en';
import rules_fr_md from '@/data/rules_fr';

export default {
  title() {
    return this.$t('view.howto.name')
  },
  components: {
    Navbar,
    GenericHeader,
  },

  computed: {
    ...mapState({
      isDarkTheme: (state) => state.settings.isDarkTheme,
    }),
    compiledMarkdown() {
      if (this.$i18n.locale == 'fr') {
        return marked(rules_fr_md);
      }
      else if (this.$i18n.locale == 'en') {
        return marked(rules_en_md);
      }
      else {
        return 'Invalid language chosen!'
      }
    }
  },
}

</script>